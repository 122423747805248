import "@css/app.css";
import Splide from '@splidejs/splide';
import '@splidejs/splide/css/core';



document.addEventListener('DOMContentLoaded', function () {
  const splideIdsToMount = ['sliderHeader', 'sliderUpcomingNews','partners'];

  const commonConfigs = {
    'sliderHeader': {
      arrows: false,
      classes: {
        pagination: 'splide__pagination custom_pagination',
      }
    },
    'sliderUpcomingNews': {
      classes: {
        pagination: 'splide__pagination upcoming_events__pagination',
        arrows: 'splide__arrows upcoming_events__arrows',
      },
      perPage: 3,
      gap: '1rem',
      padding: { left: '5px', right: '5px' },
      breakpoints: {
        1180: {
          perPage: 2,
        },
        840: {
          perPage: 1,
        }
      }
    },
    'partners' : {
      padding: { top: '5px' },
      breakpoints: {
        1180: {
          perPage: 2,
        },
        840: {
          perPage: 1,
        }
      }
    }
  };
  // Initialize all other sliders
  splideIdsToMount.forEach(splideId => {
    const splideElement = document.getElementById(splideId);
    if (splideElement) {
      if (commonConfigs[splideId]) {
        new Splide(`#${splideId}`, commonConfigs[splideId]).mount();
      } else {
        new Splide(`#${splideId}`).mount();
      }
    }
  });
});



document.querySelectorAll('figure.media > oembed[url]').forEach(element => {
  element.classList.add('parent_container_iframe');

  var wrapper = document.createElement('div');
  wrapper.classList.add('video_container_iframe');

  var iframe = document.createElement('iframe');
  iframe.src = element.getAttribute('url');
  
  iframe.style.width = '100%';
  iframe.style.height = '400px';

  wrapper.appendChild(iframe);
  element.appendChild(wrapper);
});
